<template>
  <nav v-if="!isMobile">
    <div class="logo">
      <router-link to="/">
        <img src="@/assets/logo_screx.svg" class="logo" alt="screx" />
      </router-link>
    </div>

    <div class="right-links">
      <a href="https://screx.com" class="btn_text btn_back" style="color:#fff;">back to main</a>
    </div>
  </nav>

  <nav :class="['my-element', { sticky: isSticky }]" v-if="isMobile">
    <div class="logo">
      <router-link to="/">
        <img src="@/assets/logo_screx.svg" alt="screx" />
      </router-link>
    </div>
  </nav>

  <div class="container-mobile" v-if="isMobile">
    <div class="nav-links" v-if="isMobile">
      <router-link @click="toggleMenu" to="/">About</router-link>
      <router-link @click="toggleMenu" to="/features">Features</router-link>
      <router-link @click="toggleMenu" to="/safu">SAFU</router-link>
      <router-link @click="toggleMenu" to="/dapp">dApp</router-link>
      <router-link @click="toggleMenu" class="btn_waitlist btn_waitlist_mobile" to="/join">Join waitlist</router-link>
    </div>

    <div class="right-links" v-if="isMobile">
      
      <div v-if="!isSafu" class="icons_cta">
        <a @click="toggleMenu" href="https://www.linkedin.com/company/screx/" target="_blank">
          <img src="@/assets/linkedin.svg" alt="twitter" />
        </a>
        <a href="https://x.com/screx_com" target="_blank">
          <img src="@/assets/x.svg" alt="x" />
        </a> 
        <a href="https://t.me/Screx_com" target="_blank">
          <img src="@/assets/telegram.svg" alt="telegram" />
        </a>
        <a href="https://t.me/Screx_community" target="_blank">
          <img src="@/assets/telegram.svg" alt="telegram" />
        </a>
        <a href="https://linktr.ee/screx.com" target="_blank">
          <img src="@/assets/soc.svg" alt="linktree" />
        </a>
      </div>
    </div>
  </div>

  <router-view v-slot="{ Component }">
    <transition name="route" mode="out-in">
      <component :is="Component"></component>
    </transition>
  </router-view>
  <div class="bgpage"></div>
</template>

<script>
import { useRoute } from 'vue-router';
import { ref, onMounted, onBeforeUnmount, computed, watch } from "vue";

export default {
  setup() {
    const route = useRoute();
    const isSafu = computed(() => route.path === '/safu');
    const isDapp = computed(() => route.path === '/dapp');

    console.log(isDapp);

    const isOpen = ref(false);
    const isMobile = ref(false);
    const isSticky = ref(false);

    const handleScroll = () => {
        if (window.scrollY > 5) {
            isSticky.value = true;
        } else {
            isSticky.value = false;
        }
    };

    const checkWindowSize = () => {
      isMobile.value = window.innerWidth <= 1150;
    };

    const toggleMenu = () => {
      isOpen.value = !isOpen.value;
      if(isOpen.value) {
        document.querySelector('.container-mobile').classList.add('open');
      } else {
        document.querySelector('.container-mobile').classList.remove('open');
      }
    };

    const updateBodyClass = (routeName) => {
      document.body.className = document.body.className
        .split(' ')
        .filter((cls) => !cls.startsWith('page-'))
        .join(' ');

      if (routeName) {
        document.body.classList.add(`page-${routeName.toLowerCase()}`);
      }
    };

    onMounted(() => {
      checkWindowSize();
      window.addEventListener("resize", checkWindowSize);
      window.addEventListener("scroll", handleScroll);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", checkWindowSize);
      window.removeEventListener('scroll', handleScroll);
    });

    watch(
      () => route.name,
      (newRouteName) => {
        updateBodyClass(newRouteName);
      }
    );

    return { isSafu, isDapp, isOpen, isMobile, isSticky, toggleMenu };
  }
};
</script>

<style>
#app {
  font-family: 'Darker Grotesque', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.logo {
  width:33px;
  height:25px;
}

.container-mobile {
  width: 100%;
  height: 100%;
  visibility: hidden;
  background-color: rgba(0,0,0,0.97);
  opacity:1;
  z-index: 99;
  position: fixed;
  top:0;
  right: 0;
  left: 0;
  bottom: 0;
  margin-left:-1150px;
  box-sizing: border-box;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}
.container-mobile.open {
  opacity:1;
  z-index: 99;
  margin-left:0;
  visibility: visible;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
}
.container-mobile .nav-links {
  display: block;
  text-align: right;
  margin-right: 30px;
  margin-top: 120px;
}
.container-mobile .nav-links a {
  display:block;
  color:#fff;
  font-size: 20px;
  margin-bottom: 50px;
  text-decoration: none;
}
.container-mobile .btn_waitlist_mobile {
  margin-left:auto;
  text-align: center;
}
.container-mobile .right-links {
  display: flex;
  align-items: center;
  margin-left: auto;
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
  justify-content: center;
  position:absolute;
  bottom:20px;
  z-index: 999999;
}

nav {
  padding: 25px;
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 999999999;
}

.nav-links {
  margin-left: 20%;
  display: flex;
}

.right-links {
  display: flex;
  align-items: center;
  margin-left: auto;
}

nav a {
  font-weight: 400;
  font-size: 20px;
  color: #fff;
  text-decoration: none;
  margin-right: 50px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

nav a:last-child {
  margin-right: 0px;
}

nav a.router-link-exact-active:not(.btn_waitlist) {
  background: linear-gradient(
    90deg,
    #6DBD8F 0%,
    #6ABE9D 8%,
    #67C0B5 22%,
    #63C2CF 38%,
    #60C4E3 53%,
    #59B4D9 62%,
    #478BC1 79%,
    #2D519F 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  position: relative;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
nav a:after {
  content:'';
  width: 0px;
}
nav a.router-link-exact-active:not(.btn_waitlist):after {
  content:'';
  position:absolute;
  margin-bottom:4px;
  width:100%;
  height:1px;
  left:0;
  bottom:0;
  background: linear-gradient(
    90deg,
    #6DBD8F 0%,
    #6ABE9D 8%,
    #67C0B5 22%,
    #63C2CF 38%,
    #60C4E3 53%,
    #59B4D9 62%,
    #478BC1 79%,
    #2D519F 100%
  );
  transition: width 0.3s ease;
}
.logo a::after {
  display:none;
}

/* Route Transitions */
.route-leave-active,
.route-enter-active {
  transition: opacity 0.4s ease;
  -webkit-transition: opacity 0.4s ease;
  -moz-transition: opacity 0.4s ease;
  -ms-transition: opacity 0.4s ease;
  -o-transition: opacity 0.4s ease;
}
.route-enter-from,
.route-leave-to {
  opacity: 0;
}

/* Mobile */
.hamburger {
  position: relative;
  width: 28px;
  height: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 10;
  margin-left: auto;
  margin-top:3px
}

.hamburger span {
  display: block;
  width: 28px;
  height: 2px;
  background-color: #fff;
  transition: transform 0.3s, opacity 0.3s;
}

.hamburger.open span:nth-child(1) {
  transform: translateY(5px) rotate(45deg);
}

.hamburger.open span:nth-child(2) {
  opacity: 0;
}

.hamburger.open span:nth-child(3) {
  transform: translateY(-7px) rotate(-45deg);
}

@media (min-width: 1151px) {
  .hamburger {
    display: none;
  }
  .menu {
    transform: none;
  }
}
</style>
