import { createRouter, createWebHistory } from 'vue-router'
import Dapp from '../views/DappView.vue'

const routes = [
  {
    path: '/',
    name: 'dapp',
    component: Dapp,
    meta: { title: 'Screx' }, 
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
